<template>
  <div>
    <h1>System Administration</h1>
    <div id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <div>
      <!-- add roles input -->
      <b-container
        v-show="editorVisible"
        fluid
      >
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row class="d-flex justify-space-arround flex-row">
              <b-col class="d-flex justify-content-end pr-0 pl-0">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="cancelEdit()"
                >
                  Cancel
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="ml-1"
                  @click.prevent="validationForm()"
                >
                  Save Role
                </b-button>
              </b-col>
              <!-- submit and reset -->
            </b-row>
            <b-row class="justify-space-arround flex-row pt-2">
              <b-col class="pr-0 pl-0">
                <validation-provider
                  #default="{ errors }"
                  name="user-role"
                  rules="required"
                >
                  <b-form-group>
                    <b-form-input
                      id="UserRole"
                      v-model="editingRoleObj.Name"
                      placeholder="Role Name"
                      name="user-role"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider></b-col>
              <b-col class="pr-0 pl-0">
                <b-form-group>
                  <v-select
                    v-model="editingRoleObj.SelectedUserType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="userTypes"
                    placeholder="User Type"
                    :select-size="1"
                    name="user-types"
                  />
                </b-form-group>
              </b-col>

              <b-col class="pr-0 pl-0">
                <b-form-group>
                  <b-form-input
                    id="Description"
                    v-model="editingRoleObj.Description"
                    type="text"
                    name="description"
                    placeholder="Enter Description"
                  />
                </b-form-group>
              </b-col>
              <b-col class="pr-0 pl-0">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="permissions"
                    rules="required"
                  >
                    <v-select
                      v-model="editingRoleObj.PermissionSet"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="applicablePermissions"
                      placeholder="Permission Set"
                      name="permissions"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="1"
                class="pr-0 pl-0"
              >
                <b-button
                  block
                  variant="primary"
                  style="
                    justify-content: center;
                    display: flex;
                    border-radius: 0;
                  "
                  @click="hide()"
                >
                  <span>X</span>
                  <!-- <feather-icon
              class="mr-50"
            /> -->
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-container>
      <div
        v-show="!editorVisible"
        class="row"
        style="margin-bottom: 8px;"
      >
        <div class="col-6">
          <!-- add button -->
          <div>
            <div class="col-4 pr-0 pl-0 D-flex justify-space-arround">
              <b-button
                block
                variant="primary"
                style="
                  justify-content: space-around;
                  display: flex;
                  border-radius: 0;
                "
                @click="showAddNew()"
              >
                <span>Add User Role</span>
              </b-button>
            </div>
          </div>
        </div>
        <div class="col-6 pr-0 pl-0">
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div
                class="d-flex align-items-center"
                style="margin-right: 14px;"
              >
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
        </div>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'fullName'"
            class="text-nowrap"
          >
            <b-avatar
              :src="props.row.avatar"
              class="mx-1"
            />
            <span class="text-nowrap">{{ props.row.fullName }}</span>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="startEditRole(props.row)">
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteRoleWithPrompt(props.row)">
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> {{ props.total }} entries </span>
              <b-form-select
                v-model="pageLength"
                :options="['100', '150', '200']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">per page</span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
    <b-modal
      id="modal-delete-role"
      ok-only
      ok-variant="danger"
      ok-title="Delete Role"
      modal-class="modal-danger"
      centered
      title="Confirm Delete Role"
      @ok="confirmDeleteRole()"
    >
      <div v-if="roleToDelete !== null">
        Are you sure you want to delete role {{ roleToDelete.Name }}?
      </div>
    </b-modal>
    <b-modal
      id="modal-delete-role-still-active-users"
      ok-only
      ok-title="OK"
      modal-class="modal-danger"
      centered
      title="Cannot Delete Role"
    >
      <div v-if="roleToDelete !== null">
        Role {{ roleToDelete.Name }} still has {{ roleToDelete.UserCount }} active user(s). Remove the role from the associated user(s) before deleting.
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BBreadcrumb,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BCol,
  BRow,
  BContainer,
  BForm,
  BModal,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import APIService from "@core/utils/APIService";

const apiService = new APIService();

import axios from "axios";

import store from "@/store/index";
// import { mapState } from 'vuex'

const COOKIE = "CMToken";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BBreadcrumb,
    BButton,
    BCol,
    BRow,
    BContainer,
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      cookie: "",
      editorVisible: false,
      pageLength: 100,
      dir: false,
      description: "",
      userTypes: [],
      rolesResponse: {},
      permissionsSets: "",
      permissions: [],
      selectedUserTypes: [],
      editingRoleObj: {},
      roleToDelete: null,
      allPermissionSets: null,
      columns: [
        {
          label: "User Role",
          field: "Name",
        },
        {
          label: "User Type",
          field: "EligibleUserTypes",
        },
        {
          label: "Description",
          field: "Description",
        },
        {
          label: "Users",
          field: "UserCount",
          type: 'number'
        },
        {
          label: "Permission Set",
          field: "PermissionSetName",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      breadcrumbItems: [
        {
          text: 'Admin',
          href: '#'
        },
        {
          text: "Users & Permissions",
          href: '/admin/users-and-permissions'
        },
        {
          text: "User Roles",
          active: true,
        },
      ],
      required,
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    applicablePermissions() {
      if (this.allPermissionSets == null) {
        return [];
      }

      if (
        this.editingRoleObj === undefined ||
        this.editingRoleObj.SelectedUserType === undefined ||
        this.editingRoleObj.SelectedUserType === null
      ) {
        return [];
      }

      return this.allPermissionSets
        .filter((ps) => ps.UserType === this.editingRoleObj.SelectedUserType?.value)
        .map((ps) => ({
          title: ps.Name,
          value: ps.ID,
        }));
    },
  },
  async created() {
    try {
      const response = await apiService
        .get("users/types")
        .then((res) => res.data)
        .catch((error) => error);
      const filterTypes = response.map((data) => ({
        title: data.DisplayName,
        value: data.UserType,
      }));
      this.userTypes = filterTypes;
    } catch (err) {
      this.error = err;
    }

    this.refreshRoles();

    apiService
      .get("permissions/permissionsets")
      .then((resp) => {
        const permission = resp.data.map((data) => ({
          title: data.Name,
          value: data.ID,
        }));
        this.permissions = permission;
        this.allPermissionSets = resp.data;
      })
      .catch((error) => error);
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "InfoIcon",
            text: data,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
    startEditRole(role) {
      this.editorVisible = true;
      this.editingRoleObj = {
        Name: role.Name,
        ID: role.ID,
        Description: role.Description,
        PermissionSet: role.PermissionSetID
          ? { title: role.PermissionSetName, value: role.PermissionSetID }
          : {},
        SelectedUserTypes: this.userTypes.filter((x) => {
          for (var i = 0; i < role.EligibleUserTypesArray.length; i++) {
            if (x.value === role.EligibleUserTypesArray[i]) {
              return true;
            }
          }
          return false;
        }),
        SelectedUserType: this.userTypes.filter((x) => {
          for (var i = 0; i < role.EligibleUserTypesArray.length; i++) {
            if (x.value === role.EligibleUserTypesArray[i]) {
              return true;
            }
          }
          return false;
        }).find(e => true),
      };
      console.log(role);
    },
    cancelEdit() {
      this.editorVisible = false;
      this.editingRoleObj = {};
    },
    refreshRoles() {
      apiService
        .get("permissions/roles?max=1000")
        .then((resp) => {
          this.rolesResponse = resp.data;
          const roles = resp.data.map((data) => ({
            Name: data.Name,
            ID: data.ID,
            Description: data.Description,
            EligibleUserTypes: data.EligibleUserTypes.join(", "),
            UserCount: data.UserCount,
            PermissionSetName: data.PermissionSetName,
            PermissionSetID: data.PermissionSetID,
            EligibleUserTypesArray: data.EligibleUserTypes,
          }));
          this.rows = roles;
        })
        .catch((error) => error);
    },
    processForm() {
      var postData = {
        Name: this.editingRoleObj.Name,
        PermissionSetID: this.editingRoleObj.PermissionSet.value,
        Description: this.editingRoleObj.Description,
        EligibleUserTypes: [this.editingRoleObj.SelectedUserType?.value]
      };

      var endpoint = "permissions/role/create";

      if (this.editingRoleObj.ID) {
        endpoint = "permissions/role/" + this.editingRoleObj.ID;
      }

      /* eslint-disable */
      console.log("data before post", postData);
      apiService
        .post(endpoint, postData)
        .then((response) => {
          if (response) {
            this.showToast("success", "top-center", 4000, "Role saved");
            this.hide();
            this.refreshRoles();
          }
        })
    },
    showAddNew() {
      this.editorVisible = true;
      this.editingRoleObj = {};
    },
    hide() {
      this.editorVisible = false;
    },
    deleteRoleWithPrompt(roleObj) {
      this.roleToDelete = roleObj;
      if (this.roleToDelete.UserCount > 0) {
        this.$bvModal.show("modal-delete-role-still-active-users");
        return;
      }

      this.$bvModal.show("modal-delete-role");
    },
    confirmDeleteRole() {
      if (!this.roleToDelete) {
        return;
      }
      apiService
        .delete("permissions/role/" + this.roleToDelete.ID)
        .then((resp) => {
          if (resp) {
            this.showToast("success", "top-center", 4000, "Role deleted");
            this.refreshRoles();
          }
        });
      this.roleToDelete = null;
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.processForm();
        }
      });
    },
  },
};
</script>

<style>
/* overide classes just for this view better pattern than inline styles on bootstrap components aj */
.btn--add-users,
.input-group-text {
  border-radius: 0;
}

[dir] .form-control,
[dir] .vs--searchable .vs__dropdown-toggle {
  /* border: none; */
  border-radius: 0px;
  height: 2.78rem;
}
</style>
